.c-subject {
  &__header {
    margin: 2.5rem 0;

    h2 {
      margin: 0;
      font-weight: 600;
    }

    &--split {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and ( max-width: 850px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__split {
      display: flex;
      align-items: center;

      @media screen and ( max-width: 850px) {
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and ( max-width: 650px) {
        width: 100%;
      }

      .c-form__select {
        margin-left: 3rem;

        @media screen and ( max-width: 850px) {
          margin-left: 0;
        }

        @media screen and ( max-width: 650px) {
          width: 100%;
        }


      }
    }

    &__check {
      display: flex;

      @media screen and ( max-width: 850px) {
        margin: 1rem 0;
      }

      small {
        display: none;

        @media screen and ( max-width: 850px) {
          display: flex;
          font-size: 1.7rem;
          margin-right: 2rem;
        }

      }

      label {
        display: flex;
        align-items: center;
        margin-left: 1.8rem;

        @media screen and ( max-width: 850px) {
          margin-left: 0;
          margin-right: 1.5rem;
        }

        span {
          font-weight: 700;
          margin-left: 1rem;
          font-size: 1.7rem;
        }
      }
    }
  }
}
