.c-notification {
  display: inline-flex;
  margin-top: 3rem;
  align-items: center;
  background-color: #eee;
  padding: 1rem 1rem 1rem 1.5rem;
  border-radius: .5rem;
  position: relative;

  span {
    color: $color-white;
    font-size: 1.6rem;
    padding-right: 1.5rem;
    line-height: 1.3;
  }
  i {
    font-size: 2rem;
    margin-right: 1rem;
    &:before {
      color: $color-white;
    }
  }
  &--error {
    background-color: #ff7761;
  }
  &--success {
    background-color: green;
  }
}
