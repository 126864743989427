/*------------------------------------*\
  #GLOBAL STYLES
\*------------------------------------*/

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

@media screen and ( max-width: 1350px) {
    font-size: 53%;
  }
  @media screen and ( max-width: 1023px) {
    font-size: 57%;
  }

  @media screen and ( max-width: 650px) {
    font-size: 60%;
  }

  @include mq-tablet {
    font-size: 55%;
  }
}

.s-webshop-order {
  width: 100%;
  max-width: 100%;
}

::selection {

}

body {
  overflow-x:hidden;
}

::-webkit-scrollbar {

}

::-webkit-scrollbar-thumb {
  // background-color: $scrollbar-color;
}

// img reset
img {
  max-width: 100%;
  height: auto;
}

// basic hr
hr,
.hr {
  display: block;
  height: 0.1rem;
  border: 0;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}

select::-ms-expand {
  display: none;
}

.s-webshop-order {
  //overflow-x: hidden;
}