.c-payment-methods {

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  &__selection {
    align-items: center;
    cursor: pointer;
    padding: 1rem 2.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  &__customer-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1rem 2.5rem;

    h5 {
      margin-bottom: 2rem;
    }

    span {
      font-size: 1.5rem;
    }

    input {
      width: 100%;
      height: 4rem;
      padding: 0.5rem;
      border: 1px solid #e0e0e0;
      border-radius: 0.5rem;
      font-size: 1.5rem;
      margin-bottom: 1rem;

      @media screen and (max-width: 1023px) {
        width: 50%;
      }
    }
  }

}
