.u-flex {
  display: flex;

  &--half {
    width: 50%;
    margin: 0.5rem 0;

    &:nth-of-type(2n) {
      margin: 0.5rem 0 0.5rem 1rem;
    }
  }
}