.c-period-picker{
  margin: 5rem 0 3rem;

  h5{
    font-size: 2.5rem;
    margin: 0 0 2rem;
    font-weight: 600;
  }

  &__selection{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &__item{
      width: calc(25% - (6rem / 4));
      margin-bottom: 2rem;
      margin-right: 2rem;

      &:nth-child(4n){
        margin-right: 0;
      }

      @media screen and ( max-width: 900px) {
        width: calc((100% / 3) - (4rem / 3));

        &:nth-child(3n){
          margin-right: 0;
        }
      }

      @media screen and ( max-width: 560px) {
        width: calc(50% - 1rem);

        &:nth-child(2n){
          margin-right: 0;
        }
      }

      @media screen and ( max-width: 370px) {
        width: 100%;
        margin-right: 0;
      }

      button{
        width: 100%;
        margin-bottom: 1rem;
      }

      div{
        span{
          font-size: 1rem!important;
        }
      }
    }
  }
}
