// bower:scss
// endbower
@import '0.defaults/defaults';
@import '1.objects/objects';
@import '2.components/components';
@import '3.utilities/utilities';
@import '4.themes/themes';
@import '5.scope/scope';
@import '6.is-has/is-has';
@import '7.hacks/hacks';


.c-summaries {
  margin-top: 2rem;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrCalendarWrapper {
  width: 100%;
  height: 42.5rem;
}

.rdrMonths {
  justify-content: space-between;
}

.rdrMonth {
  background: #f4f8fe;
  padding: 1rem 4rem 3rem !important;
  width: 100% !important;
}

@media screen and ( max-width: 900px) {
  .rdrMonth {
    background: #f4f8fe;
    padding: 1rem 4rem 3rem !important;
    width: calc(100%) !important;
    margin-bottom: 2rem;
  }

  .rdrMonths {
    display: block !important;
  }
}

.rdrDay {
  margin: .6rem 0;
}

.rdrDayNumber span {
  color: #002B6D !important;
}

.rdrDay, .rdrStartEdge, .rdrDayEndOfMonth .rdrInRange, .rdrInRange, .rdrEndEdge {

  top: 0px !important;
  bottom: 0px !important;
}

.rdrInRange {
  border-radius: 0 !important;
}

.rdrStartEdge, .rdrEndEdge {
  color: #042b6d !important;
}

.rdrInRange {
  color: #fff !important;
}

.rdrMonthName {
  text-align: center !important;
}

.rdrDayNumber span {
  font-size: 1.4rem;
}

.rdrInRange + .rdrDayNumber span {
  color: #fff !important
}

.rdrDayStartPreview{
  border: none !important;
}

.rdrDayStartPreview + .rdrDayNumber span {
  color: #fff !important
}

.rdrSelected + .rdrDayNumber span {
  color: #fff !important
}

.rdrDayPassive {
  opacity: 0;
}

.c-date-picker {
  margin: 3rem 0;
}

.c-date-picker__button {
  margin: 2rem 0;
}


.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: .4rem !important;
  border-bottom-left-radius: .4rem !important;
  border-left-width: 1px;
  left: 0px;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: .4rem !important;
  border-bottom-right-radius: .4rem !important;
  border-right-width: 1px;
  right: 0px;


}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 0px !important;
  left: 0px;
  right: -4px;
  bottom: 0px !important;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
  border-color: #042b6d !important;

}

.rdrDayStartPreview {
  left: 7px !important;
}

.rdrDayEndPreview {
  right: 7px !important;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: .4rem !important;
  border-bottom-left-radius: .4rem !important;
  left: 0px;

}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: .4rem !important;
  border-bottom-right-radius: .4rem !important;
  left: 0;
  right: 0;
}

.rdrNextPrevButton {
  margin: 0 !important;
  background: #fff !important;
  width: 3.5rem !important;
  height: 3.5rem !important;
  margin-bottom: 2rem;
}

.rdrMonthAndYearWrapper {
  padding: 2rem!important;
  border-bottom: 1px solid
}


.rdrStartEdge ~ .rdrDayNumber span, .rdrEndEdge ~ .rdrDayNumber span {
  color: #fff !important;
}


.rdrMonthAndYearPickers select {
  font-size: 1.6rem !important;
  color: #042b6d !important;
}

.rdrNextPrevButton i {
  border: 0 !important;
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rdrNextPrevButton i:before {
  content: "\e900";
  color: #fff;
  font-size: .8rem;
}

.rdrNextButton {
  background: #042b6d !important;
}

.rdrNextButton i:before {
  display: block !important;
  transform: translateY(.3rem) translateX(.8rem) rotate(-90deg) !important;
  color: #fff;

}

.rdrPprevButton i:before {
  display: block !important;
  transform: translateY(-1rem) translateX(0rem) rotate(90deg) !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #042b6d !important;
}

.rdrWeekDay, .rdrMonthName {
  color: #042b6d !important;
}

.rdrMonthName {
  font-size: 1.6rem;
}

.rdrDayDisabled {
  opacity: 0.2;
  background: none !important;
}

.red-option {
  color: orange !important;
}

.c-select-search {
  font-size: 1.6rem;
}

.custom-location {
  width: 100%;
  border: 1px solid hsl(0, 0%, 80%) !important;
  margin-top: 1rem;
  font-size: 1.5rem;
  padding: .7rem 1.4rem;
  border-radius: .8rem;

  &-select {
    padding: 0;
    border: 0 !important;

  }
}

.c-marker {
  width: 2.7rem;
  min-width: 2.7rem;
  height: 2.7rem;
  min-height: 2.7rem;
  border-radius: .6rem;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  border-color: #fff;
  font-size: 1.8rem !important;
  font-weight: 700 !important;
  font-family: 'Cabin', serif;

  &:hover {
    cursor: pointer;
  }

  span {

  }

  &.is-selected {
    border-color: #002B6D;
    background: #002B6D;
    color: #fff !important;

  }
}

.c-delivery-options__single {
  &.is-disabled {
    pointer-events: none;

    .c-delivery-options__check, h5, p {
      opacity: .4;
    }
  }
}

body {
  padding-bottom: 6rem;
}

.c-sticky-footer .btn {
  border: none;
  background-color: #f70;
  color: #fff;

  span {
    margin: 0;
  }
}

.c-loader {
  display: flex;
  justify-content: center;
  padding: 5rem;
}


body {
  min-height: 120vh;
}

html {
  scroll-behavior: smooth;
}


.step-enter {
  opacity: 0 !important;

  &.step-enter-active {
    opacity: 1 !important;
    transition: all 800ms ease-in-out !important;
  }
}

.step-leave {
  opacity: 1 !important;

  .step-leave-active {
    opacity: 0 !important;
    transition: none !important;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.2;
  transition: opacity 500ms linear;
}

.fade-exit-done {
  opacity: 0;
}

.fade-appear {
  opacity: 0;
}

.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.bottom-enter {
  transform: translateY(100%);
}

.bottom-enter-active {
  transform: translateY(0);
  transition: transform 200ms ease-in-out;
}

.h-days-amount {
  margin-left: 2rem;
}

.c-loader-full {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
}

.c-order-overview__products__single__container {
  flex: 1;
}

.c-order-overview__payment-button button:disabled {
  opacity: .3
}

.not-deliverable {
  color: #fff;
  background: #bb4034;
  padding: .2rem .8rem;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.availability-header {
  text-align: center;
  color: #fff;
  height: 25px;
  background: green;
  font-weight: bold;
  padding: .5rem 0;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -1px;
  position: relative;
}

.c-delivery-options__store__options__single {
  align-items: center;
}


hr {
  border-bottom: 1px solid #042b6d;
  margin: 2rem 0;
  opacity: .1;
}

.c-lang-selector.is-disabled {
  pointer-events: none;
  opacity: .2;
}

.c-delivery-options {
  display: flex;
  flex-wrap: wrap;

  .c-delivery-options__single {
    min-width: 100%;
    order: 1;

    &.is-disabled {
      order: 2;
    }
  }
}

@media screen and ( max-width: 1550px) {
  #launcher {
    bottom: 7rem !important;
  }
}

.c-custom-button {

  font-size: 1.2rem;
  margin-top: 8rem;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &.inactive {
    color: #000 !important;
  }
}


.custom-row {
  display: flex;
  justify-content: space-between;

  > * {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &-1 {
    > * {
      &:nth-child(2) {
        width: 40rem;
      }
    }
  }

  &-2 {
    > * {

      &:nth-child(1) {
        width: 40rem;
      }

      &:last-child {

      }
    }
  }

}

.custom-row-1 {
  &:last-child {
    max-width: 4rem;
  }
}

.c-footer-link {
  background: #ededed;
  padding: 2rem 0 4rem;
  text-align: center;

  a {
    color: inherit;
    font-size: 1.6rem;
    text-decoration: underline;
  }
}


.language-de {
  .c-subject-bar__single h2 {
    font-size: 1.4rem !important;
  }
}

.c-order-overview__qr {
  display: flex;
  align-items: center;
  height: 100%;

  img {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}

.delivery-options.is-hidden {
  display: none;
}

.btn {
  border: 0;
}

.c-notification--error {
  margin-right: 1rem;
}

.insurance-icon-info {
  font-size: 2rem;
}

.c-modal__body__text {
  margin: 2rem 0 2.4rem;
}

.g-client--8 {

  .c-date-picker__button, .default-button-container .btn--primary, .c-customer-info .btn--primary {
    position: fixed;
    right: 1rem;
    bottom: 0;
  }

  .default-button-container .btn--primary, .c-customer-info .btn--primary {
    bottom: 1.5rem !important;
  }

  .c-products__single__locations {
    scrollbar-color: #999 #333;

    &::-webkit-scrollbar {
      width: 10px; /* Mostly for vertical scrollbars */

    }

    &::-webkit-scrollbar-thumb { /* Foreground */
      background: #ededed;
    }

    &::-webkit-scrollbar-track {
      background: #272727;
    }
  }

}

.c-modal__locations {
  max-height: 50rem;
  overflow: auto;
}


.carousel .slider{
  z-index: 1;
}

.carousel-root{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .control-prev.control-arrow:before{
  border-right: 8px solid #000!important;
}
.carousel .control-next.control-arrow:before{
  border-left: 8px solid #000!important;
}

.carousel.carousel-slider .control-arrow{
  opacity: 1!important;
}

.carousel .control-dots .dot{
  background: #000!important;
}


.rotate {
  animation: rotation 1s infinite linear;
  transform-origin: center center;
}

.wave {
  animation: wave 3s infinite;
  transform-origin: center center;
}

@keyframes wave {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  90% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.c-custom-loader-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-custom-loader {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 175px;
}
.c-custom-loader small {
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.c-custom-loader__peddles {
  position: absolute;
  top: 50px;
  left: 75px;
}
.c-custom-loader__peddles rect, .c-custom-loader__peddles polygon {
  fill: black;
}
.c-custom-loader #frame-1, .c-custom-loader #frame-2, .c-custom-loader #frame-3, .c-custom-loader #frame-4, .c-custom-loader #saddle {
  fill: red;
  z-index: 1;
  position: relative;
}
.c-custom-loader #Oval, .c-custom-loader #wheel-b, .c-custom-loader #wheel-f {
  fill: black;
}

.c-bicycle-popup-carousel{
  .carousel.carousel-slider{
    overflow: visible!important;
    .control-dots{
      bottom: -32px!important;
    }
  }
}

.c-bicycle-carousel{
  .carousel.carousel-slider{
    overflow: visible!important;
    .control-dots{
      bottom: -30px!important;
      .dot{
        width: 5px!important;
        height: 5px!important;
        margin: 0 2px!important;
      }
    }
  }
}

.c-products__single__locations__single__wrapper{
  flex: 1;
}
.rdrCalendarWrapper{
  height: 100%;
}