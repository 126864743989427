.c-summary {
  border: 1px solid;
  border-radius: 1rem;
  padding: 2rem 3rem;
  position: relative;
  margin-bottom: 1.5rem;

  &__edit {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    font-size: 1.6rem;
    padding: 0;
    margin-left: 2rem;

    @media screen and ( max-width: 600px) {
      text-decoration: none;
      @include square-dimensions(3.5rem);
      border-radius: .6rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      @media screen and ( max-width: 600px) {
        display: none;
      }
    }

    i {
      display: none;

      @media screen and ( max-width: 600px) {
        display: block;
      }
    }
  }

  &__check {
    position: absolute;
    background-color: $color-white;
    font-size: 2.5rem;
    top: 2rem;
    left: -1.2rem;
  }

  &__subject {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid;

    &:last-of-type {
      padding: 0;
      margin: 0;
      border-bottom: transparent;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and ( max-width: 600px) {
        margin-bottom: .5rem;
      }

      h5 {
        font-size: 1.6rem;
        margin: 0;
      }
    }
  }

  &__line {
    display: flex;
    span {
      font-size: 1.6rem;
    }
  }
  &__amount {
    width: 5rem;
    min-width: 5rem;
    max-width: 5rem;
  }
  &__product {
    width: 20rem;
    min-width: 20rem;
    max-width: 20rem;

    @media screen and ( max-width: 600px) {
      width: 100%;
      min-width: auto;
      max-width: 100%;
      padding-right: 2rem;
    }
  }
  &__totals {
    display: flex;
    align-items: center;

    @media screen and ( max-width: 600px) {
      justify-content: space-between;
    }

    h5 {
      font-size: 1.6rem;
      width: 25rem;
      min-width: 25rem;
      max-width: 25rem;
      margin: 0;
      color: $color-black;

      @media screen and ( max-width: 600px) {
        min-width: auto;
        max-width: auto;
        width: auto;
        margin-right: 2rem;
      }

    }
    span {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }


  &--period {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
      margin-right: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media screen and ( max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      span {
        font-size: 1.6rem;
      }
      .btn {
        margin-left: 1rem;

        span {
          color: $color-white !important;
        }

        @media screen and ( max-width: 600px) {
          margin-left: 0;
        }
      }
    }
  }

  &--totals {
    margin: 0 0 1.5rem;
  }
}

.c-summary .c-summary--period__text span, .c-summary .c-summary__edit {
  color: $color-black;
}

.c-summary--totals span, .c-summary--totals h5, .c-summary--totals a, .c-summary--totals button {
  color: $color-black;
}
