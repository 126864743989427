.c-products {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: calc((-100vw / 2) - -900px / 2);
    width: 100vw;
    height: 100%;
    background-color: $color-gray;
    z-index: -1;

    @media screen and ( max-width: 1000px) {
      left: calc((-100vw / 2) - (-100% - 0px) / 2);
    }
  }

  &__single {
    display: flex;
    flex-direction: column;
    width: 28.5rem;
    min-width: 28.5rem;
    border-radius: 1.8rem;
    margin: 0 2.1rem 2.1rem 0;
    background-color: $color-white;
    border: none !important;

    &:nth-of-type(3n+3) {
      margin: 0 0 2.1rem;

      @media screen and ( max-width: 1023px) {
        margin: 0 1rem 1rem 0;
      }
    }

    @media screen and ( max-width: 1350px) {
      width: 33.97rem;
      min-width: 33.97rem;
    }

    @media screen and ( max-width: 1023px) {
      width: calc(50% - .5rem);
      min-width: calc(50% - .5rem);
      margin: 0 1rem 1rem 0;

      &:nth-of-type(even) {
        margin: 0 0 1rem 0;
      }
    }

    @media screen and ( max-width: 650px) {
      width: 100%;
      min-width: 100%;
      margin: 0 0 1rem 0;

      &:nth-of-type(even) {
        margin: 0 0 1rem 0;
      }

      &:nth-of-type(3n+3) {
        margin: 0 0 1rem 0;
      }
    }


    &__price {
      font-size: 1.8rem;
      font-weight: 600;

      small {
        font-weight: 400;
        font-size: 1.2rem;
        margin-left: .5rem;
      }
    }

    &__info {
      display: flex;
      padding: 1.9rem 1.9rem 1.4rem;

      &__primary {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        order: 2;
        width: 100%;


        &__name {
          display: flex;
          flex-direction: column;
          min-height: 7rem;

          h4 {
            font-size: 1.7rem;
            font-weight: 500;
            line-height: 1.1;
            margin: 0;
          }

          p {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 0.8;
            margin-top: 1rem;
            margin-bottom: 2rem;
          }
        }

        &__wrapper {

        }
      }

      &__secondary {
        display: flex;
        flex-direction: column;
        order: 1;
        margin-right: 1.4rem;
      }
    }

    &__media {
      @include square-dimensions(10.3rem);
      border-radius: .8rem;
      margin-bottom: 1rem;
      display: flex;
      border: none !important;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        max-height: 10rem;
        max-width: 10rem;
      }
    }

    &__amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .7rem;

      @media screen and ( max-width: 1350px) {
        justify-content: flex-start;
      }

      .minus {
        i {
          font-size: 5px;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        @include square-dimensions(3.5rem);
        border: none;
        border-radius: .5rem;

        i {
          font-size: 14px;
        }
      }

      span {
        font-size: 2rem;

        @media screen and ( max-width: 1350px) {
          margin: 0 2rem;
        }
      }
    }

    &__modal-trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      width: 100%;
      border-radius: .6rem;
      padding: .2rem;

      i {
        font-size: 1.2rem;
        margin-right: .5rem;

        &:before {
          color: $color-black;
        }
      }

      span {
        font-size: 1.2rem;
      }
    }

    &__locations {
      max-height: 20.5rem;
      overflow-x: auto;

      &__single {
        padding: 1rem 1.9rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.is-deactivated {
          pointer-events: none;
          opacity: .3;
        }

        &__wrapper {
          display: flex;
          align-items: center;
        }

        button {
          margin-right: 1.2rem;
          @include square-dimensions(3rem);
          border-radius: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;

          i {
            font-size: 1.4rem;
          }
        }

        &__name {
          display: flex;
          flex-direction: column;

          span {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1;
          }

          small {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;
          }
        }

        &__amount {
          display: flex;
          align-items: center;

          i {
            font-size: 1.4rem;
            margin-right: .5rem;
          }

          small {
            font-size: 1.3rem;
            font-weight: 700;
            width: 2rem;
            text-align: right;
          }
        }
      }
    }

    &--full {
      //pointer-events: none;
    }

    &--hidden {
      display: none;
    }

    &--inactive {
      opacity: .25;
      pointer-events: none;
    }


  }
}

.c-products-alternative {
  &__wrap {
    display: flex;
  }

  &__products {
    display: flex;
    flex-wrap: wrap;

    .c-products__single {
      width: 23.5rem;
      min-width: 23.5rem;
      margin: 0 1.5rem 1.5rem 0;

      @media screen and ( max-width: 850px) {
        width: calc(50% - .8rem);
        min-width: calc(50% - .8rem);
        margin: 0 1.5rem 1.5rem 0;

        &:nth-of-type(even) {
          margin: 0 0 1.5rem 0;
        }
      }
      @media screen and ( max-width: 550px) {
        width: 100%;
        min-width: 100%;
        margin: 0 0 1.5rem 0;

        &:nth-of-type(even) {
          margin: 0 0 1.5rem 0;
        }
      }

      &__media {
        margin: 0 auto;
      }

      &__modal-trigger {
        width: auto;
        display: inline-flex;
        margin: 0 auto;
        padding: .25rem 1rem;
        background-color: white;
        position: relative;
        z-index: 1;
      }

      &__info {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__secondary {
          margin-right: 0;
          align-items: flex-start;
          justify-content: center;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            bottom: 1.37rem;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #ededed;
            z-index: 1;
          }
        }

        &__primary {
          position: relative;

          &__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          h4 {
            display: flex;
            align-items: center;
            height: auto;
            padding: 1rem 0;
          }
        }
      }
      &__amount {
        margin-bottom: 0;


        span {
          width: 3rem;
          min-width: 3rem;
          text-align: center;
          font-size: 1.7rem;

          @media screen and ( max-width: 1350px) {
            margin: 0;
          }
        }
        button {
          @include  square-dimensions(3rem);
        }
      }




      &__price {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
      }
    }
  }

  &__aside {
    width: 30rem;
    min-width: 30rem;

    @media screen and ( max-width: 850px) {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      background-color: white;
      z-index: 2;
      width: 100%;
      min-width: 100%;
      transform: translateX(100%);
    }

    &__close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      @include square-dimensions(4rem);
      align-items: center;
      justify-content: center;
      background-color: black;
      border-radius: 50%;
      border: none;
      display: none;


      @media screen and ( max-width: 850px) {
        display: flex;
      }

      i {
        font-size: 1.5rem;

        &:before {
          color: white;
        }
      }
    }

    &__map {
      background-color: lightblue;
      min-height: 25rem;
      height: 25rem;
      position: relative;
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;

      &__number {
        @include square-dimensions(2rem);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: black;

        span {
          color: white;
          font-weight: 600;
          font-size: 1.3rem;
        }
      }
    }

    &__title {
      background-color: white;
      padding: .5rem 1rem;
      border-bottom: 1px solid #ededed;
      min-height: 3.5rem;
      height: 3.5rem;

      span {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      overflow: hidden;
      padding-bottom: 8rem;

      @media screen and ( max-width: 850px) {
        overflow-y: auto;
        max-height: calc(100vh - 28.5rem);
      }

      &__single {
        background-color: white;
        border-bottom: 1px solid #ededed;

        &__wrap {
          display: flex;
          align-items: center;
          padding: 1rem 1rem 0 1rem;

          .c-products-alternative__aside__map__number {
            margin-right: .5rem;
          }

          small {
            font-size: 1.4rem;
            font-weight: 600;
          }
        }

        address {
          padding: 1rem;
          font-style: normal;
          font-size: 1.3rem;
        }

        &:last-child{
          border-bottom-left-radius: 1.5rem;
          border-bottom-right-radius: 1.5rem;
        }
      }

      .unavailable {
        opacity: .4;
        cursor: not-allowed;

        small {
          text-decoration: line-through;
        }
      }
    }
  }
  .isActive {
    transform: translateX(0);
  }
}

.c-products-alternative__avail-btn {
  width: 100%;
  background-color: black;
  border: none;
  padding: 1rem 2rem;
  border-radius: .5rem;
  margin-bottom: 1rem;
  display: none;

  @media screen and ( max-width: 850px) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  span {
    font-size: 1.6rem;
    font-weight: 400;
    color: white;
  }
}

