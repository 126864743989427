.c-time-picker{
  width: calc(50% - 2.5rem);
  margin: 3rem 0;

  @media screen and ( max-width: 900px) {
    width: 100%;
    margin-top: 5rem;
  }

  &__info{
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #ededed;

    h5{
      font-size: 2rem;
      margin: 0 0 0.5rem;
      font-weight: 600;
    }
  }

  &__content{
    padding: 1rem 4rem !important;
    width: 100% !important;
    color: #042b6d !important;
    display: flex;
    flex-direction: column;
    height: 42.5rem;


    @media screen and ( max-width: 1350px) {
      height: 47.3rem;
    }

    &__start-date{
      margin: 2rem 0;

      span{
        font-size: 1.5rem;
      }
    }


    &__amount{
      margin: 2rem 0;

      &__picker{
        display: flex;

        input{
          border-radius: 5px;
          text-align: center;
          border: none;
          width: 6rem;
          height: 4rem;
          pointer-events: none;
          -moz-appearance: textfield;
        }

        input[type="time"]::-webkit-calendar-picker-indicator {
          display: none;
        }


        input[type="number"]::-webkit-inner-spin-button {
          display: none;
        }

        button{
          display: block;
          border-radius: 5px;
          width: 35px;
          height: 35px;
          padding: 0;
          border: 0;
          font-size: 1.4rem;
          color: #fff;
          background: #555555 !important;

          &[disabled]{
            opacity: .3;
          }

          &.min{
            margin-right: 1rem;
          }

          &.plus{
            margin-left: 1rem;
          }
        }
      }
    }


    &__start-time{
      margin: 2rem 0;

      &__picker{
        select{
          border-radius: 5px;
          border: none;
          padding: 1rem 2rem;
          font-size: 1.4rem;
        }
      }
    }


    &__end-time{
      margin: 2rem 0;

      span{
        font-size: 1.6rem;
      }
    }
  }
}
