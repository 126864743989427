.s-date-time-picker{
  display: flex;
  justify-content: space-between;

  @media screen and ( max-width: 900px) {
    flex-direction: column;
  }

  &__singleDate{
    display: flex;
    align-items: center;
    margin: 4rem 0;

  }

}
