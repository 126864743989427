.c-sticky-footer {
  padding: 1rem 3rem;
  background-color: $color-white;
  width: 100%;
  max-width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;

  @media screen and ( max-width: 750px) {
    padding: 1rem 3rem 2rem;
  }

  @media screen and ( max-width: 600px) {
    padding: 1rem 2rem 2rem;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 130rem;
    width: 100%;

    @media screen and ( max-width: 1023px) {
      justify-content: center;
    }
  }

  &__selection {
    display: flex;
    align-items: center;

    @media screen and ( max-width: 1023px) {
      display: none;
    }

    span {
      font-size: 1.6rem;
      margin-right: 1rem;

      strong {
        font-weight: 600;
      }
    }
  }
  &__totals {
    display: flex;
    align-items: center;

    @media screen and ( max-width: 1023px) {
      width: 100%;
      justify-content: space-between;
    }

    span {
      margin-right: 2rem;
      font-size: 1.6rem;
      font-weight: 600;

      strong {
        font-weight: 600;
      }
    }
    button {
      padding: .75rem 1.75rem;
      color: #fff;

      span {
        text-align: center;
        margin: 0;
        font-size: 1.7rem;
        font-weight: 600;
        color: $color-black;
      }
    }
  }
}

.c-sticky-footer .btn span{
  color: #fff !important;
}
