.c-delivery-options {

  &__check {
    @include square-dimensions(2rem);
    border-radius: 50%;
    background-color: $color-white;
    border: 2px solid;
    margin-right: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    figure {
      @include square-dimensions(1rem);
      border-radius: 50%;
      background-color: $quaternary-color;
    }
  }

  &__text {
    h5 {
      margin: 0;
      font-size: 2rem;
      text-align: left;
    }
    p {
      line-height: 1.3;
    }
  }

  &__single {
    border-radius: 1rem;
    overflow: hidden;
    border: 1px solid;
    background-color: $color-white;
    margin-bottom: 1.5rem;

    &__header {
      display: flex;
      padding: 2rem 2.7rem;
      width: 100%;
      border: transparent;
      background-color: $color-white;

      @media screen and ( max-width: 650px) {
        padding: 2rem;
      }
    }

    &--standard {
      padding: 2.7rem;
      display: flex;
      text-align: left;

      @media screen and ( max-width: 650px) {
        padding: 2rem;
      }
    }
  }
  &__store {
    &__select-store {
      display: flex;
      border-top: 1px solid;

      @media screen and ( max-width: 750px) {
        flex-direction: column;
      }
    }

    &__options {
      width: 50%;
      padding: 3rem 4rem 4rem 4rem;

      small {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        display: block;
      }

      @media screen and ( max-width: 750px) {
        width: 100%;
        padding: 2rem;
      }

      &__single {
        display: flex;
        padding: 1.7rem;
        border-radius: 1rem;
        box-shadow: none;
        margin-bottom: 1.2rem;
        border: 2px solid transparent;

        @media screen and ( max-width: 750px) {
          width: 100%;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
        &__number {
          @include square-dimensions(2.7rem);
          border-radius: .6rem;
          border: 2px solid;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-right: 1.5rem;

          span {
            font-size: 1.8rem;
            font-weight: 700;
          }
        }
        &__text {
          display: flex;
          flex-direction: column;
          text-align: left;
          span {
            font-size: 1.5rem;
            line-height: 1.2;
            &:first-of-type {
              font-size: 1.6rem;
              font-weight: 700;
            }
          }
        }
      }
    }
    &__map {
      width: 50%;

      @media screen and ( max-width: 750px) {
        width: 100%;
        height: 25rem;
        min-height: 25rem;
      }
    }
  }
}
