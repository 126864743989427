
.c-time-picker{
  margin: 0 !important;
}

.c-date-picker {
  width: calc(50% - 2.5rem);
  margin: 0 !important;



  &--old{
    .rdrMonthAndYearWrapper{
      background: #fff;
      border: 0 !important;
      padding: 0 !important;
    }

    .rdrMonthsHorizontal{
      background: #fff;

      .rdrMonth{
        width: calc(50% - 1rem) !important;

        @media screen and ( max-width: 900px) {
          width: 100% !important;
        }
      }
    }
  }

  @media screen and ( max-width: 900px) {
    width: 100%;
  }

  &__button {
    display: inline-flex;
  }
  &__info {
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    h5 {
      font-size: 2rem;
      margin: 0 0 .5rem;
      font-weight: 600;
    }
    div {
      display: flex;
      align-items: center;

      .btn--pill {
        padding: .5rem 1.5rem;

        @media screen and ( max-width: 650px) {
          padding: 0;
          background-color: transparent !important;
          span {
            font-weight: 400;
            color: #000000;
          }
        }
      }
      .h-days-amount {
        @media screen and ( max-width: 650px) {
          padding: .5rem 1.5rem;
          background-color: black !important;
          span {
            text-align: center;
            color: white;
            font-weight: 600;
            font-size: 1.4rem !important;
          }
        }
      }

      small {
        margin: 0 .5rem !important;
      }

      p, small {
        margin: 0;
        font-size: 1.6rem;

        @media screen and ( max-width: 650px) {
          font-size: 1.7rem !important;
        }
        @media screen and ( max-width: 550px) {
          font-size: 1.5rem !important;
        }
      }
    }
  }
}

.rdrCalendarWrapper{
  height: auto !important;
}